import { validation } from 'site-modules/shared/components/form-validation/validation';

export const VALIDATORS = {
  firstName: {
    test: validation.validateName,
    errorText: 'Please enter a valid first name',
  },
  lastName: {
    test: validation.validateName,
    errorText: 'Please enter a valid last name',
  },
  email: {
    test: validation.validateEmail,
    errorText: 'Please enter a valid email address',
  },
  phone: {
    test: validation.validatePhoneNumber,
    errorText: 'Please enter a valid phone number',
  },
  style: {
    test: validation.validateDigits,
    errorText: 'Please select a style',
  },
  selectedLeadsEmpty: {
    test: validation.validateValue,
    errorText: 'Please select one or more dealerships.',
  },
};

export const VALIDATION_URL = '/uservalidation/v1/validatecontact?id=';

export const PHONE_MASK = {
  pattern: '(999) 999-9999',
  toValue: true,
};

export const PHONE_MASK_V2 = {
  pattern: '(000) 000-0000',
  toValue: true,
};

export const SCROLL_MAP = [
  { input: 'firstName', selector: '#lead-form-fname' },
  { input: 'lastName', selector: '#lead-form-lname' },
  { input: 'email', selector: '#lead-form-email' },
  { input: 'phone', selector: '#lead-form-phone' },
  { input: 'selectedLeadsEmpty', selector: '.ulf-inventory-card' },
];
