import { EventToolbox } from 'client/utils/event-toolbox';
import { arePixelsEqual, buildPixelData, isPixelEmpty } from './core-pricing-carousel-tracking';

export const CORE_PRICING_CAROUSEL_EVENTS = {
  INITIAL_LOAD: 'corePricingCarousel.initialLoad',
  DATA_UPDATE: 'corePricingCarousel.dataUpdate',
};

function handleInitialLoad({ detail: { coreConfigurations, leadFormDealerCount }, target }) {
  EventToolbox.fireTrackAction(buildPixelData(target, { coreConfigurations, leadFormDealerCount, isPageLoad: true }));
}

function handleDataUpdate({
  detail: { previousCoreConfigurations, coreConfigurations, leadFormDealerCount, previousLeadFormDealerCount },
  target,
}) {
  const previousPixelData = buildPixelData(target, {
    coreConfigurations: previousCoreConfigurations,
    leadFormDealerCount: previousLeadFormDealerCount,
    isPageLoad: false,
  });
  const pixelData = buildPixelData(target, { coreConfigurations, leadFormDealerCount, isPageLoad: false });

  if (!arePixelsEqual({ previousPixelData, pixelData }) && !isPixelEmpty({ pixelData })) {
    EventToolbox.fireTrackAction(pixelData);
  }
}

export const CorePricingCarouselEngagementHandler = {
  init: () => {
    EventToolbox.on(CORE_PRICING_CAROUSEL_EVENTS.INITIAL_LOAD, handleInitialLoad);
    EventToolbox.on(CORE_PRICING_CAROUSEL_EVENTS.DATA_UPDATE, handleDataUpdate);
  },
};
