import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';

import {
  LEAD_TYPES,
  CARD_VIEW,
  HEADER_COMPONENT,
  THANK_YOU_VIEWS,
} from 'site-modules/shared/constants/lead-form/lead-form-params';
import { CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import {
  getVehicleProps,
  getPathname,
  getLeadSubmissionInfoWithoutEmail,
} from 'site-modules/shared/components/lead-form/utils/utils';
import { sendMarketoThankYouEmail } from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
import { withLeadFormCoreWrapper } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/transparent-pricing-lead-form-core-wrapper';

export function TransparentPricingLeadFormUI({ params, options, leadFormProps, isUserSetZip }) {
  const { vehicle, styleId, trim, zipCode, radius, srpLink } = params;
  const {
    isMobile,
    isUserOpened = false,
    creativeId,
    hideIncentives,
    hideCovidIncentives,
    coreConfiguration,
    bestDealPrice,
    userInfoPositionMobile,
    userInfoPositionDesktop,
    userInfoOptions,
    submitButtonText,
    cardView,
    explainingTextMobile,
    explainingTextDesktop,
    inventoryTextMobile,
    inventoryTextDesktop,
    headerComponent,
    headingText,
    headingClassName,
    headingTag,
    leadFormClassName,
    leadFormFragmentTop,
    leadFormTrackedCreativeId,
    thankYouClassName,
    thankYouHeaderComponent,
    thankYouAdsConfig,
    thankYouComponent,
    thankYouPriceValue,
    thankYouHeadingText,
    thankYouExplainingText,
    thankYouLayoutOptions,
    thankYouNextButtonConfig,
    loadingComponent,
    leadFormComponent,
    emptyLeadFormComponent,
    leadFormLayoutOptions,
    hasPriceCheckerPromo,
    priceCheckerParams,
    utmCampaign,
    isBuildPriceAdEnabled,
    initialCollapseState,
    processDataMethods,
    leadFormConfig,
    leadFormComponentConfig,
    hideZipForm,
    apiParams,
    noLeadTargets,
    hasEmailTreehousePromotion,
  } = options;
  const srpLinkConfig = { text: 'See all for sale', to: srpLink };

  const explainingTextParams = [
    {
      name: 'trim',
      value: get(coreConfiguration, 'trim', trim),
    },
    {
      name: 'style',
      value: get(coreConfiguration, 'style'),
    },
  ];

  const defaultExplainingText = `**Did you know?** You should know exactly what you should pay for a car before you test drive it. ${
    bestDealPrice
      ? `\n\nEdmunds suggests you pay about ${formatPriceString(
          bestDealPrice
        )}. We'll show you what to pay on the next page.`
      : '\n\n{minBestDealPrice=0?:Edmunds suggests you pay about {minBestDealPrice}, see if these dealers can beat it!}'
  }`;
  const mobileExplainingText =
    typeof explainingTextMobile === 'undefined' ? defaultExplainingText : explainingTextMobile;
  const desktopExplainingText =
    typeof explainingTextDesktop === 'undefined' ? defaultExplainingText : explainingTextDesktop;

  const mobileInventoryText =
    inventoryTextMobile || 'The price you should pay is <span class="text-primary-darker">on the next page</span>';
  const desktopInventoryText = inventoryTextDesktop || "We'll show you the price you should pay.";

  const explainingText = isMobile ? mobileExplainingText : desktopExplainingText;

  const priceCheckerParamsObj = {
    ...priceCheckerParams,
    bestDealPrice: bestDealPrice || get(coreConfiguration, 'bestDealPrice'),
  };

  const customConfig = {
    leadType: LEAD_TYPES.ANY,
    creativeId: creativeId || CREATIVE_ID.NEW_MULTI_CORE,
    noLeadTargets,
    isUserOpened,
    apiParams,
    processDataMethods: {
      postProcessMethods: [...get(processDataMethods, 'postProcessMethods', []), sendMarketoThankYouEmail],
      getLeadSubmissionInfo: get(processDataMethods, 'getLeadSubmissionInfo') || getLeadSubmissionInfoWithoutEmail,
    },
    ...(utmCampaign && { utmCampaign }),
    leadFormConfig: {
      ...leadFormConfig,
      fragmentTop: leadFormFragmentTop,
      trackedCreativeId: leadFormTrackedCreativeId,
      cardView: cardView || CARD_VIEW.DEALER_LIST,
      submitButtonText: submitButtonText || "Get Dealer's Actual Price|Get Dealers' Actual Price",
      userInfoPosition: isMobile ? userInfoPositionMobile || 1 : userInfoPositionDesktop,
      userInfoOptions: { isLabelInside: true, ...userInfoOptions },
      preSelectedCardNumbers: 3,
      headerConfig: {
        headerComponent: typeof headerComponent === 'undefined' ? HEADER_COMPONENT.EMBEDDED : headerComponent,
        header: {
          showLogo: false,
          text: headingText || '[savingsValue=0?Save with Edmunds:Save up to {savingsValue} with Edmunds]',
          explainingText,
          className: headingClassName,
          tag: headingTag,
        },
      },
      showIncentives: !hideIncentives,
      showCovidIncentives: !hideCovidIncentives,
      inventoryText: isMobile ? mobileInventoryText : desktopInventoryText,
      className: leadFormClassName,
      withFormClasses: !leadFormClassName,
      reverseCardView: true,
      listCardView: true,
      initialCollapseState,
      options: {
        layoutOptions: leadFormLayoutOptions,
      },
      bestDealPrice: bestDealPrice || get(coreConfiguration, 'bestDealPrice'),
    },
    loadingComponent,
    leadFormComponent,
    emptyLeadFormComponent,
    thankYouComponent: thankYouComponent || THANK_YOU_VIEWS.TRANSPARENT_PRICING_PRICE_CHECKER,
    thankYouConfig: {
      className: thankYouClassName,
      withFormClasses: !thankYouClassName,
      headerConfig: {
        headerComponent:
          typeof thankYouHeaderComponent === 'undefined' ? HEADER_COMPONENT.PRICE_CHECKER : thankYouHeaderComponent,
        header: {
          text: thankYouHeadingText || 'Smart move!',
          explainingText:
            typeof thankYouExplainingText === 'undefined'
              ? 'You’re on your way to savings. Here’s what to do next:'
              : thankYouExplainingText,
          showSeparator: 'false',
        },
      },
      options: {
        priceValue: thankYouPriceValue,
        srpLink: srpLinkConfig,
        layoutOptions: thankYouLayoutOptions,
        nextButton: thankYouNextButtonConfig,
      },
      adsConfig: thankYouAdsConfig || { adComponent: null },
      hasPriceCheckerPromo,
      hasEmailTreehousePromotion,
      priceCheckerParams: priceCheckerParamsObj,
      isBuildPriceAdEnabled,
    },
    explainingTextParams,
  };

  const formProps = {
    ...leadFormProps,
    ...getVehicleProps()(vehicle),
    showZipForm: !hideZipForm && !isUserSetZip,
    styleId,
    radius: radius || 100,
    zipCode,
    trim,
    pathname: getPathname(options),
    config: leadFormComponentConfig || LEAD_FORM_CONFIGS.QUICK_QUOTES_TRIM_DST,
  };

  return <UnifiedLeadFormAsync {...formProps} customConfig={customConfig} />;
}

TransparentPricingLeadFormUI.propTypes = {
  params: PropTypes.shape({
    zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    radius: PropTypes.number,
    vehicle: PropTypes.shape({
      make: PropTypes.shape({ name: PropTypes.string }),
      model: PropTypes.shape({ name: PropTypes.string }),
      submodels: PropTypes.shape({ name: PropTypes.string, slug: PropTypes.string }),
      year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  }).isRequired,
  options: PropTypes.shape({
    isMobile: PropTypes.bool,
    isUserOpened: PropTypes.bool,
    creativeId: PropTypes.string,
  }).isRequired,
  leadFormProps: PropTypes.shape({
    wrapperClassName: PropTypes.string,
    closeDrawer: PropTypes.func,
  }),
  isUserSetZip: PropTypes.bool,
};

TransparentPricingLeadFormUI.defaultProps = {
  leadFormProps: null,
  isUserSetZip: false,
};

const mapStateToProps = state => ({
  isUserSetZip: !!get(state, 'visitor.location.userSet'),
});

export const TransparentPricingLeadForm = withLeadFormCoreWrapper(
  connect(mapStateToProps)(TransparentPricingLeadFormUI)
);
