const OEM_URL_WHITELIST = ['WWW.NISSANCOMMERCIALVEHICLES.COM'];

const NON_MSRP_DEFAULT_MAKE_PARAMS = {
  nissan: '',
};

export const isNonMSRPMake = make => Object.keys(NON_MSRP_DEFAULT_MAKE_PARAMS).includes(make);

export const getNonMSRPDefaultLabel = make => NON_MSRP_DEFAULT_MAKE_PARAMS[make];

export const isOemUrlWhitelisted = url => OEM_URL_WHITELIST.some(u => u === url);
