import { objectToQueryString } from './string';

const BUILD_PRICE_PARAMS = ['modelyearId', 'submodel', 'styleId'];

/**
 * @param {Object} params - CorePageParams signature
 * @param {string} params.make
 * @param {string} params.model
 * @param {number=} params.year
 * @param {number=} params.modelyearId
 * @param {string=} params.submodel Submodel identifier. Should be name, not nice name. E.g. 'Sedan', not 'sedan'
 * @param {number=} params.styleId
 * @param {boolean=} isPriceBreakdown link to price breakdown if possible
 * @returns {String} - Build and Price url
 * */
export function getBuildAndPriceLink({ make, model, year, submodel, styleId, modelyearId }, isPriceBreakdown) {
  let queryObj = { modelyearId, submodel, styleId };

  // Each param requires all others which stand before.
  BUILD_PRICE_PARAMS.forEach((param, index) => {
    queryObj[param] = BUILD_PRICE_PARAMS.slice(0, index).every(restParam => queryObj[restParam])
      ? queryObj[param]
      : null;
  });

  // If only modelyearId exist then it should be defaultValue
  if (queryObj.modelyearId && !queryObj.submodel) {
    queryObj = {
      defaultValue: modelyearId,
    };
  }

  const query = objectToQueryString(queryObj) || objectToQueryString({ defaultYear: year });

  return `/${make}/${model}/build/${query ? `?${query}` : ''}${
    isPriceBreakdown && !!queryObj.styleId ? '&priceBreakdown' : ''
  }`;
}
