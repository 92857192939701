import React, { useCallback, useState } from 'react';
import { venomHistory } from 'client/utils/history/venom-history';

export function internationalZipPickerMethods(WrappedComponent) {
  function ZipPickerWrapper(props) {
    const { inventoryLink } = props;
    const [isZipChangeInProgress, setZipChangeInProgress] = useState(false);
    const [showActiveSpinner, setShowActiveSpinner] = useState(false);

    const redirect = useCallback(() => {
      venomHistory.push(inventoryLink);
    }, [inventoryLink]);

    const onBtnClick = useCallback(() => {
      setShowActiveSpinner(true);

      if (!isZipChangeInProgress) {
        redirect();
      }
    }, [isZipChangeInProgress, redirect]);

    const onZipChangeInProgress = useCallback(({ isZipChangeInProgress: isInProgress }) => {
      setZipChangeInProgress(isInProgress);
    }, []);

    return (
      <WrappedComponent
        onBtnClick={onBtnClick}
        onZipChangeInProgress={onZipChangeInProgress}
        showActiveSpinner={showActiveSpinner}
        {...props}
      />
    );
  }

  return ZipPickerWrapper;
}
