import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {
  HEADER_COMPONENT,
  LEAD_FORM_VIEWS,
  LOADING_COMPONENT,
} from 'site-modules/shared/constants/lead-form/lead-form-params';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { LeadFormDrawer } from 'site-modules/shared/components/lead-form/lead-form-drawer/lead-form-drawer';
import { WhiteBackButton } from 'site-modules/shared/components/lead-form/lead-form-drawer/close-buttons/white-back-button/white-back-button';
import { BaseEmptyView } from 'site-modules/shared/components/lead-form/unified-lead-form/views/empty-view/base-empty-view';
import { TransparentPricingLeadForm } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/transparent-pricing-lead-form';

const SAVINGS_THRESHOLD = 250;

export function TransparentPricingLeadFormDrawer({ isOpen, onDrawerToggle, params, options, creativeId }) {
  const estimatedSavings = options.coreConfiguration?.estimatedSavings || options.estimatedSavings;
  const headingText =
    estimatedSavings &&
    `Save${estimatedSavings > SAVINGS_THRESHOLD ? ` ${getPriceString(estimatedSavings)}` : ''} with Edmunds`;

  return (
    <LeadFormDrawer
      url=""
      isOpen={isOpen}
      onDrawerToggle={onDrawerToggle}
      component={TransparentPricingLeadForm}
      componentData={{
        params,
        options: {
          loadingComponent: LOADING_COMPONENT.CONVEX,
          leadFormComponent: LEAD_FORM_VIEWS.MODULAR,
          headerComponent: HEADER_COMPONENT.CONVEX,
          thankYouHeaderComponent: HEADER_COMPONENT.CONVEX,
          emptyLeadFormComponent: BaseEmptyView,
          headingText,
          ...options,
          thankYouLayoutOptions: { withConvexHeader: true, ...options.thankYouLayoutOptions },
        },
      }}
      containerClassName="pos-st"
      drawerContentClassName="bg-cool-gray-90"
      closeBtnComponent={WhiteBackButton}
      dataTrackingParent={creativeId}
    />
  );
}

TransparentPricingLeadFormDrawer.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool,
  onDrawerToggle: PropTypes.func,
  creativeId: PropTypes.string,
};

TransparentPricingLeadFormDrawer.defaultProps = {
  isOpen: false,
  onDrawerToggle: noop,
  creativeId: undefined,
};
