import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { VisitorEntities } from 'client/data/models/visitor';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';

export function MakeModelLocationDefault({
  children,
  makeModelSubmodelYear,
  params,
  visitorLocation,
  isHighlights,
  disableTrademarkCharacter,
}) {
  const displayVehicle = buildDisplayVehicle({ makeModelSubmodelYear, params, disableTrademarkCharacter });
  const city = get(visitorLocation, 'city', '');
  const stateCode = get(visitorLocation, 'stateCode', '');

  return (
    <Fragment>
      {children}
      {isHighlights && <h2 className="display-2 text-start size-24 fw-bold mb-0_5">{displayVehicle} Trim Pricing</h2>}
      <div className="text-gray-darker fw-normal text-start size-16">
        {isHighlights ? (
          <Fragment>
            <div className="fw-normal size-16 d-inline-block mb-0">
              {city}, {stateCode}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {displayVehicle} pricing in {city}, {stateCode}
          </Fragment>
        )}
        <ZipWithDefaultActionHandling buttonClassName="size-16 color-primary-darker fw-normal" />
      </div>
    </Fragment>
  );
}

MakeModelLocationDefault.propTypes = {
  children: PropTypes.node,
  params: CorePageParams.isRequired,
  makeModelSubmodelYear: VehicleEntities.MakeModelSubmodelYear,
  visitorLocation: VisitorEntities.Location,
  isHighlights: PropTypes.bool,
  disableTrademarkCharacter: PropTypes.bool,
};

MakeModelLocationDefault.defaultProps = {
  children: null,
  makeModelSubmodelYear: null,
  visitorLocation: {},
  isHighlights: false,
  disableTrademarkCharacter: false,
};
