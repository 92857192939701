import { calculateEstimatedSavingsConfiguration } from 'site-modules/shared/utils/core-page/core-configurations';
import { TrackingConstant } from 'client/tracking/constant';
import { getCreativeId } from 'client/engagement-handlers/helper';

export function arePixelsEqual({ previousPixelData, pixelData }) {
  const previousEventData = previousPixelData.event_data;
  const eventData = pixelData.event_data;

  return (
    previousEventData.est_savings === eventData.est_savings &&
    previousEventData.match_inv_list.length === eventData.match_inv_list.length &&
    previousEventData.match_total === eventData.match_total
  );
}

/**
 * Return true if est_savings and match_total in event_data are zero
 * @param   {Object} pixelData
 * @return  {boolean}
 */
export function isPixelEmpty({ pixelData }) {
  const eventData = pixelData.event_data;
  return eventData.est_savings === 0 && eventData.match_total === 0;
}

export function buildPixelData(target, { isPageLoad, coreConfigurations, leadFormDealerCount }) {
  const estSavings = calculateEstimatedSavingsConfiguration(coreConfigurations)?.estimatedSavings || 0;
  const matchInvList = [];
  let matchTotal = 0;

  if (coreConfigurations && coreConfigurations.length > 0) {
    let inventoryCount = 0;

    coreConfigurations.forEach(coreConfiguration => {
      inventoryCount = coreConfiguration.inventoryCount || 0;
      matchTotal += inventoryCount;
      matchInvList.push(inventoryCount);
    });
  }

  return {
    event_type: 'action_completed',
    event_data: {
      action_name: TrackingConstant.ACTION_SAVING_RETRIEVAL,
      creative_id: getCreativeId(target),
      est_savings: estSavings,
      match_inv_list: matchInvList,
      match_total: matchTotal,
      lf_dealer: leadFormDealerCount || matchTotal ? 'y' : 'n',
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_cause: isPageLoad ? 'page_load' : 'data_update',
    },
  };
}
